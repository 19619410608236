<template>
    <div class="app-container">
        <el-form ref="form" :model="activityInfoDo" label-width="120px">
            <div class="content-title">活动说明</div>
            <div class="con-body">
                <el-form-item label="活动名称" prop="activityName">
                    <el-input disabled v-model="activityInfoDo.activityName"></el-input>
                </el-form-item>
                <el-form-item label="活动类型" prop="policyNo">
                    <el-select disabled v-model="activityInfoDo.policyNo" placeholder="请选择">
                        <el-option v-for="item in policyList" :key="item.id" :label="item.policyName"
                            :value="item.policyNo"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="活动说明">
                    <el-input disabled type="textarea" v-model="activityInfoDo.activityRemark"></el-input>
                </el-form-item>
            </div>
            <div class="content-title">活动内容</div>
            <div class="con-body">
                <el-form-item label="资金流转">
                    <span>资金从一级机构开始下发扣8%税点</span>
                </el-form-item>
                <el-form-item label="商户激活">
                    <div>
                        <el-form-item prop="activeType">
                            <el-select disabled v-model="activityInfoDo.activeType" placeholder="请选择">
                                <el-option label="交易满额" value="trans_target"></el-option>
                                <el-option label="交易冻结" value="trans_freeze"></el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <div style="padding:10px 0" v-if="activityInfoDo.activeType == 'trans_target'">
                        商户审核通过后
                        <el-form-item prop="activeRewardDays" class="form-inline">
                            <span class="activeInp"><el-input disabled v-numberInt:0
                                    v-model="activityInfoDo.activeRewardDays"></el-input></span>
                        </el-form-item>
                        天内交易≥
                        <el-form-item prop="activeTargetAmount" class="form-inline">
                            <span class="activeInp2"><el-input disabled v-numberInt
                                    v-model="activityInfoDo.activeTargetAmount"></el-input></span>
                        </el-form-item>
                        元，奖励
                        <el-form-item prop="activeRewardAmount" class="form-inline">
                            <span class="activeInp2"><el-input disabled v-numberInt
                                    v-model="activityInfoDo.activeRewardAmount"></el-input></span>元
                        </el-form-item>
                    </div>
                    <div style="padding:10px 0" v-if="activityInfoDo.activeType == 'trans_freeze'">
                        冻结满
                        <el-form-item prop="activeTargetAmount" class="form-inline">
                            <span class="activeInp2"><el-input disabled v-numberInt
                                    v-model="activityInfoDo.activeTargetAmount"></el-input></span>
                        </el-form-item>
                        元，奖励
                        <el-form-item prop="activeRewardAmount" class="form-inline">
                            <span class="activeInp2"><el-input disabled v-numberInt
                                    v-model="activityInfoDo.activeRewardAmount"></el-input></span>元
                        </el-form-item>
                    </div>
                </el-form-item>
                <el-form-item label="商户重复激活">
                    <div>
                        <el-form-item prop="activeRepeatStatus" class="form-inline">
                            <el-select disabled v-model="activityInfoDo.activeRepeatStatus" placeholder="请选择">
                                <el-option label="是" :value="1"></el-option>
                                <el-option label="否" :value="0"></el-option>
                            </el-select>
                        </el-form-item>
                        <span v-if="activityInfoDo.activeRepeatStatus == '1'">
                            奖励 <el-form-item prop="activeRepeatRewardAmount" class="form-inline">
                                <span class="activeInp2"><el-input disabled v-numberInt
                                        v-model="activityInfoDo.activeRepeatRewardAmount"></el-input></span></el-form-item>元
                        </span>
                    </div>
                </el-form-item>
                <el-form-item label="逾期未激活">
                    <div>
                        机具入库后
                        <el-form-item prop="expireReduceDays" class="form-inline">
                            <span class="activeInp"><el-input disabled v-numberInt:0
                                    v-model="activityInfoDo.expireReduceDays"></el-input></span>
                        </el-form-item>
                        天内未激活，扣除
                        <el-form-item prop="expireReduceAmount" class="form-inline">
                            <span class="activeInp2"><el-input disabled v-numberInt
                                    v-model="activityInfoDo.expireReduceAmount"></el-input></span>
                        </el-form-item>
                        元
                    </div>
                </el-form-item>
                <el-form-item label="绑定未激活">
                    <div>
                        商户绑定后
                        <el-form-item prop="bindReduceDays" class="form-inline">
                            <span class="activeInp"><el-input disabled v-numberInt:0
                                    v-model="activityInfoDo.bindReduceDays"></el-input></span>
                        </el-form-item>
                        天内未激活，扣除
                        <el-form-item prop="bindReduceAmount" class="form-inline">
                            <span class="activeInp2"><el-input disabled v-numberInt:3
                                    v-model="activityInfoDo.bindReduceAmount"></el-input></span>
                        </el-form-item>
                        元
                    </div>
                </el-form-item>
                <el-form-item prop="invalidStatus" label="失效激活">
                    <el-select disabled v-model="activityInfoDo.invalidStatus" placeholder="请选择">
                        <el-option label="否" :value="0"></el-option>
                        <el-option label="是" :value="1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label=" " v-if="activityInfoDo.invalidStatus == '1'">
                    机具入库超出{{ invalidRewardDays }}（激活天）天后，再激活，奖励<el-form-item prop="invalidRewardAmount"
                        class="form-inline"><span class="activeInp2"><el-input disabled v-numberInt
                                v-model="activityInfoDo.invalidRewardAmount"></el-input></span>元</el-form-item>
                </el-form-item>
                <el-form-item label="伪激活">针对已激活商户,
                    <el-form-item prop="fakeType" class="form-inline">
                        <el-select disabled v-model="activityInfoDo.fakeType" placeholder="请选择">
                            <el-option label="商户激活" :value="1"></el-option>
                            <el-option label="商户开通" :value="2"></el-option>
                        </el-select>后
                    </el-form-item>
                    <el-form-item prop="fakeReduceDays" class="form-inline"><span class="activeInp"><el-input disabled
                                v-numberInt:0 v-model="activityInfoDo.fakeReduceDays"></el-input></span>天内交易＜</el-form-item>
                    <el-form-item prop="fakeTargetAmount" class="form-inline"><span class="activeInp2"><el-input disabled
                                v-numberInt v-model="activityInfoDo.fakeTargetAmount"></el-input></span>元，扣除</el-form-item>
                    <el-form-item prop="fakeReduceAmount" class="form-inline"><span class="activeInp2"><el-input disabled
                                v-numberInt v-model="activityInfoDo.fakeReduceAmount"></el-input></span>元</el-form-item>
                </el-form-item>
                <el-form-item label="商户达标">
                    <div>
                        <el-form-item prop="standardType">
                            <el-select disabled v-model="activityInfoDo.standardType" placeholder="请选择">
                                <el-option label="逐次达标" value="each"></el-option>
                                <el-option label="一次达标" value="once"></el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <div v-if="activityInfoDo.standardType == 'each'">
                        <div style="padding:10px 0 5px">
                            商户审核通过后
                            <el-form-item prop="standard1StartDays" class="form-inline">
                                <span class="activeInp"><el-input disabled v-numberInt:0
                                        v-model="activityInfoDo.standard1StartDays"></el-input></span></el-form-item>~
                            <el-form-item prop="standard1EndDays" class="form-inline"><span class="activeInp"><el-input
                                        disabled v-numberInt:0
                                        v-model="activityInfoDo.standard1EndDays"></el-input></span></el-form-item>
                            天内交易≥
                            <el-form-item prop="standard1TargetAmount" class="form-inline"><span class="activeInp2"><el-input
                                        disabled v-numberInt
                                        v-model="activityInfoDo.standard1TargetAmount"></el-input></span></el-form-item>
                            元，奖励
                            <el-form-item prop="standard1RewardAmount" class="form-inline"><span class="activeInp2"><el-input
                                        disabled v-numberInt
                                        v-model="activityInfoDo.standard1RewardAmount"></el-input></span>元</el-form-item>
                        </div>
                        <div style="padding:10px 0 5px">
                            商户审核通过后
                            <el-form-item prop="standard2StartDays" class="form-inline"><span class="activeInp"><el-input
                                        disabled v-numberInt:0
                                        v-model="activityInfoDo.standard2StartDays"></el-input></span>~</el-form-item>
                            <el-form-item prop="standard2EndDays" class="form-inline"><span class="activeInp"><el-input
                                        disabled v-numberInt:0
                                        v-model="activityInfoDo.standard2EndDays"></el-input></span></el-form-item>
                            天内交易≥
                            <el-form-item prop="standard2TargetAmount" class="form-inline"><span class="activeInp2"><el-input
                                        disabled v-numberInt
                                        v-model="activityInfoDo.standard2TargetAmount"></el-input></span></el-form-item>
                            元，奖励
                            <el-form-item prop="standard2RewardAmount" class="form-inline"><span class="activeInp2"><el-input
                                        disabled v-numberInt
                                        v-model="activityInfoDo.standard2RewardAmount"></el-input></span>元</el-form-item>
                        </div>
                        <div style="padding:10px 0 5px">
                            商户审核通过后
                            <el-form-item prop="standard3StartDays" class="form-inline"><span class="activeInp"><el-input
                                        disabled v-numberInt:0 v-model="activityInfoDo.standard3StartDays"
                                        key="13"></el-input></span>~</el-form-item>
                            <el-form-item prop="standard3EndDays" class="form-inline"><span class="activeInp"><el-input
                                        disabled v-numberInt:0 v-model="activityInfoDo.standard3EndDays"
                                        key="14"></el-input></span></el-form-item>
                            天内交易≥
                            <el-form-item prop="standard3TargetAmount" class="form-inline"><span class="activeInp2"><el-input
                                        disabled v-numberInt:3 v-model="activityInfoDo.standard3TargetAmount"
                                        key="15"></el-input></span></el-form-item>
                            元，奖励
                            <el-form-item prop="standard3RewardAmount" class="form-inline"><span class="activeInp2"><el-input
                                        disabled v-numberInt:3 v-model="activityInfoDo.standard3RewardAmount"
                                        key="16"></el-input></span>元</el-form-item>
                        </div>
                    </div>
                    <div v-if="activityInfoDo.standardType == 'once'">
                        <div style="padding:10px 0 5px">
                            商户激活后
                            <el-form-item prop="standard1EndDays" class="form-inline"><span class="activeInp"><el-input
                                        disabled v-numberInt:0
                                        v-model="activityInfoDo.standard1EndDays"></el-input></span></el-form-item>
                            天内交易≥
                            <el-form-item prop="standard1TargetAmount" class="form-inline"><span class="activeInp2"><el-input
                                        disabled v-numberInt
                                        v-model="activityInfoDo.standard1TargetAmount"></el-input></span></el-form-item>
                            元
                        </div>
                        <div style="padding:10px 0 5px">
                            商户激活后
                            <el-form-item prop="standard2EndDays" class="form-inline"><span class="activeInp"><el-input
                                        disabled v-numberInt:0
                                        v-model="activityInfoDo.standard2EndDays"></el-input></span></el-form-item>
                            天内交易≥
                            <el-form-item prop="standard2TargetAmount" class="form-inline"><span class="activeInp2"><el-input
                                        disabled v-numberInt
                                        v-model="activityInfoDo.standard2TargetAmount"></el-input></span></el-form-item>
                            元
                        </div>
                        <div style="padding:10px 0 5px">
                            奖励
                            <el-form-item prop="standard1RewardAmount" class="form-inline"><span class="activeInp2"><el-input
                                        disabled v-numberInt
                                        v-model="activityInfoDo.standard1RewardAmount"></el-input></span></el-form-item>
                            元
                        </div>
                    </div>
                </el-form-item>
                <el-form-item prop="transCountType" label="交易统计方式">
                    <el-select v-model="activityInfoDo.transCountType" placeholder="请选择" disabled>
                        <el-option label="贷记卡刷卡交易" :value="1"></el-option>
                        <el-option label="全类型交易" :value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="flowStatus" label="是否配置流量卡">
                    <el-select v-model="activityInfoDo.flowStatus" placeholder="请选择" disabled>
                        <el-option label="是" :value="1"></el-option>
                        <el-option label="否" :value="0"></el-option>
                    </el-select>
                </el-form-item>
                <div v-if="activityInfoDo.flowStatus == '1'">
                    <div v-if="activityInfoDo.flowCost3">
                        <el-form-item label="4G流量卡1" required>
                            下发金额<span class="activeInp2"><el-form-item prop="flowCost" class="form-inline"><el-input
                                        v-numberInt:3 v-model="activityInfoDo.flowCost"
                                        disabled></el-input></el-form-item></span>元
                        </el-form-item>
                        <el-form-item label="4G流量卡2" required>
                            下发金额<span class="activeInp2"><el-form-item prop="flowCost2" class="form-inline"><el-input
                                        v-numberInt:3 v-model="activityInfoDo.flowCost2"
                                        disabled></el-input></el-form-item></span>元
                        </el-form-item>
                        <el-form-item label="4G流量卡3" required>
                            下发金额<span class="activeInp2"><el-form-item prop="flowCost3" class="form-inline"><el-input
                                        v-numberInt:3 v-model="activityInfoDo.flowCost3"
                                        disabled></el-input></el-form-item></span>元
                        </el-form-item>
                    </div>
                    <div v-if="!activityInfoDo.flowCost3">
                        <el-form-item label="收取条件" required><el-form-item prop="flowStartDays" class="form-inline">
                                商户审核通过<el-input v-numberInt:0 class="inline-input" v-model="activityInfoDo.flowStartDays"
                                    disabled></el-input> ~ <el-input v-numberInt:0 class="inline-input"
                                    v-model="activityInfoDo.flowEndDays" disabled></el-input>天后收取
                                <el-input v-numberInt:3 v-model="activityInfoDo.flowCost" disabled
                                    class="inline-input" style="width: 120px;"></el-input>元</el-form-item>
                        </el-form-item>
                        <el-form-item prop="flow2StartDays" label="收取条件" required><el-form-item prop="flow2StartDays"
                                class="form-inline">
                                商户审核通过<el-input v-numberInt:0 class="inline-input" v-model="activityInfoDo.flow2StartDays"
                                    disabled></el-input> ~ <el-input v-numberInt:0 class="inline-input"
                                    v-model="activityInfoDo.flow2EndDays" disabled></el-input>天后收取
                                <el-input v-numberInt:3 v-model="activityInfoDo.flowCost2" disabled
                                    class="inline-input" style="width: 120px;"></el-input>元</el-form-item>
                        </el-form-item>
                    </div>
                </div>

            </div>
        </el-form>
    </div>
</template>
<script>
import { PolicyApi } from '@/api'
export default {
    data() {
        return {
            activityInfoDo: {},
            policyList: []
        }
    },
    created() {
        let activityNo = this.$route.query.activityNo
        PolicyApi.policyInfo.getPolicyList()
            .then(res => {
                if (res.success) {
                    this.policyList = res.data
                }
            })
        PolicyApi.activityInfo.detail(activityNo)
            .then(res => {
                if (res.success) {
                    this.activityInfoDo = res.data
                    console.log(this.activityInfoDo)
                }
            })
    }
}
</script>
<style lang="scss">
.inline-input {
    display: inline-block;
    width: 60px;
}
</style>